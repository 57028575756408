.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Establece el alto mínimo de la página para que el footer se quede abajo */
}

.content-wrap {
  flex: 1; /* Hace que el contenido ocupe el espacio disponible */
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  margin-top: auto; /* Hace que el footer se quede abajo incluso si el contenido es pequeño */
}